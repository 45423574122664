import React, { useEffect, useState } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Avatar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Home from './home';
import { useNavigate } from 'react-router-dom';
import baseurl from './settings.json';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function Banner(props) {
  const [open, setOpen] = React.useState(false);
  const [openmsg, setOpenmsg] = React.useState(false);
  
  const [msg, setmsg]= useState('');
  const handleClickOpen = (id) => {
    setOpen(true);
    setid(id);
  };
  const [tid, setid] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosemsg = () => {
    setOpenmsg(false);
  };
  const handlechangeview=(id)=>{
    axios.post(baseurl.baseurl+'/changeviewid',{
  id: id,
  }).then((response)=>{
  if (response.data.status==="Success"){
    getbannerlist();
    setmsg('View ID changed Successfully');
    setOpenmsg(true);
  }else
  {
   console.log(response.data.message);
  }
  })
  }
  const handledeleteview=(id)=>{
    setOpen(false);
    axios.post(baseurl.baseurl+'/deleteviewid',{
  id: id,
  }).then((response)=>{
  if (response.data.status==="Success"){
    getbannerlist();
    setmsg('View ID Deleted Successfully');
    setOpenmsg(true);
  }else
  {
   console.log(response.data.message);
  }
  })
  }
  const columns = [
    
    { field: 'id', headerName: 'DBID', height: 100, width: 150, hideable: true},
    { field: 'sbt', headerName: 'Slider Text', height: 100, width: 150 },
    { field: 'transtext', headerName: 'Transition Text', height: 100, width: 150 },
    { field: 'othertext', headerName: 'Sub Text', height: 100, width: 150 },
   
    {
      field: 'eanbledisable', headerName: 'Publish', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e)=>handlechangeview(id)}
          >
  
            <Typography sx={{ ml: "5px" }}  >
              {enabledisable}
            </Typography>
          </Button>
        );
      }
    },
    {
      field: 'delete', headerName: 'Actions', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e)=>handleClickOpen(id)}
          >
  
            <Typography sx={{ ml: "5px" }}  >
              Delete
            </Typography>
          </Button>
        );
      }
    }
  ];
  const [isloading, setIsloading] = useState(false);
  const [message, setmessage] = useState('');
  const [tsbt, settsbt] = useState('');
  const [trntext, settrntext] = useState('');
  const [othertext, setothertext] = useState('');
  const [stickytext, setstickytext] = useState('');
  const [rows, setrows]= useState([]);
  const navigate= useNavigate();
const  getbannerlist=()=>{
  axios.get(baseurl.baseurl+'/getbannerslist',{
        headers:{
            "x-access-token": localStorage.getItem("token"),
        },
    }).then((response)=>{
      setrows(response.data);
    })
}
  useEffect( ()=>{
    const token= localStorage.getItem("token");
    if (!token){
      navigate("/login");
    }else{
      getbannerlist();
    }
  },[!rows])
  const handlesave = () => {
    setIsloading(true);
    setmessage('');
    if (tsbt === '' || trntext === '' || othertext === '' ) {
      setmessage("All fields are required...");
      console.log(message);
      return;
    }
    console.log("t "+localStorage.getItem("token"));
    axios.post(baseurl.baseurl+"/savebanner",  {sbt: tsbt,
    transtext : trntext,
    othertext : othertext,
    stickytext : stickytext,
    token: localStorage.getItem("token")
    }
    ).then((response) => {
      console.log("r " +response.data.auth);

      if (response.data.auth === false) {
        console.log('entered');
        localStorage.removeItem("token");
       navigate('/login');
        setmessage(response.data.message);
      } else {
        console.log(response.data);
       setrows(response.data);
      
      }
    })
    setIsloading(false);
  }
  return (
    <>
      <Home />
      <Grid container columnSpacing={1} rowSpacing={1} my={4} paddingLeft={2} paddingRight={2}>
        <Grid item xs={12} sm={6}>
          <Box>
            <form
              autoComplete="off"
              noValidate
              {...props}
            >
              <Card>
                <CardHeader
                  subheader="Enter Details Below"
                  title="Sliding Banner Content"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Sliding Banner Text"
                        name="sbt"
                        onChange={(e) => settsbt(e.target.value)}
                        required
                        value={tsbt}
                        variant="outlined"
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Transition Text"
                        name="trntext"
                        onChange={(e) => settrntext(e.target.value)}
                        required
                        value={trntext}
                        variant="outlined"
                        helperText='Enter text with comma seperated'
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Sub Text"
                        name="othertext"
                        onChange={(e) => setothertext(e.target.value)}
                        required
                        value={othertext}
                        variant="outlined"
                        multiline
                        rows={2}
                        helperText="Use '|' to split rows"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Sticky Header Text"
                        name="stickytext"
                        onChange={(e) => setstickytext(e.target.value)}
                        hidden
                        value={stickytext}
                        variant="outlined" style={{display:'none'}}
                        multiline
                        rows={2}
                      />
                    </Grid>

                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2
                  }}
                >
                  <Typography color={'red'}>{message}</Typography>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={handlesave}
                    loading={isloading}
                  >
                    Save details
                  </LoadingButton>
                </Box>
              </Card>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>

            <Card>
              <CardHeader
                subheader="Enable/Disable Banner Content"
                title="List of Banner Texts"
              />
              <Divider />

              <CardContent >
                {/* Banner Data List */}
                <Box height="50vh">
                  <DataGrid rows={rows} columns={columns} columnVisibilityModel={{id: false}}/>
                </Box>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >

             
              </Box>
            </Card>

          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you Sure, DO You Want to Delete Selected Banner ...???
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={(e)=>handledeleteview(tid)} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={openmsg}
            onClose={handleClosemsg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Message"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
               {msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosemsg}>Close</Button>
              </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  )
}
export default Banner;