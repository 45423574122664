import React, { useEffect, useState } from 'react';

import Home from './home';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import baseurl from './settings.json';
function Stickytext(props) {
    const navigate= useNavigate();
    const [page, setPage] = React.useState([]);
    const [open, setOpen] = React.useState(false);
  const [openmsg, setOpenmsg] = React.useState(false);
  
  const [msg, setmsg]= useState('');
  const handleClickOpen = (id) => {
    setOpen(true);
    setid(id);
  };
  const [tid, setid] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosemsg = () => {
    setOpenmsg(false);
  };
    const [values, setValues] = useState({
       svalue:'',
       link:'',
       linkbuttonname:''
      });
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });

      };
      const handlesave=()=>{
        axios.post(baseurl.baseurl+"/savesticky",  
        {token : localStorage.getItem("token"),
        stickytext: values.svalue,
        link: values.link,
        linkbuttonname: values.linkbuttonname
        }
        ).then((response) => {
          if (response.data.auth === false) {
           localStorage.removeItem("token");
           navigate('/login');
          } else {
          setmsg(response.data.message);
          setOpenmsg(true);
          }
        })
      }
      const getstickydata=()=>{
      
        axios.get(baseurl.baseurl+'/stickydata?token='+localStorage.getItem("token")
            ).then((response)=>{
                if (response.data.auth === false){
                    navigate('/login');
                    return;
                }
            if (response.data.status==="Success"){
                console.log(response.data.results);
            setValues({
                svalue: response.data.results[0].stickytext,
                link: response.data.results[0].link,
                linkbuttonname: response.data.results[0].linkbuttonname});
            }else
            {
             console.log(response.data.message);
            }
            })
      }
      useEffect(()=>{
getstickydata();
      },[])
    return (
        <>
            <Home />
            <Grid container columnSpacing={1} rowSpacing={1} my={4} paddingLeft={2} paddingRight={2}>
                <Grid item xs={12} sm={6}>
                    <Box>
                    <form
                            autoComplete="off"
                            noValidate
                            {...props}
                        >
                            <Card>
                                <CardHeader
                                    subheader="Enter Details Below"
                                    title="Notification Text"
                                />
                                <Divider />
                                <CardContent>
                                    
                                    <Grid container spacing={3}>
         
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                             <TextField
                                                fullWidth
                                                label="Notification"
                                                name="svalue"
                                                onChange={handleChange}
                                                required
                                                value={values.svalue}
                                                variant="outlined"
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Link"
                                                name="link"
                                                onChange={handleChange}
                                                required
                                                value={values.link}
                                                variant="outlined"
                                                rows={2}
                                            />
                                            
                                        </Grid>      
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Link Button Name"
                                                name="linkbuttonname"
                                                onChange={handleChange}
                                                required
                                                value={values.linkbuttonname}
                                                variant="outlined"
                                                rows={2}
                                            />
                                            
                                        </Grid>      
                                            </Grid>
                                </CardContent>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: 2
                                    }}
                                >
                                    <Typography color={'red'}></Typography>
                                    <LoadingButton
                                        color="primary"
                                        variant="contained"
                                        onClick={handlesave}

                                     >
                                        Save details
                                    </LoadingButton>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
          <Dialog
            open={openmsg}
            onClose={handleClosemsg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Message"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
               {msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosemsg}>Close</Button>
              </DialogActions>
          </Dialog>
        </Grid>
            </Grid>
        </>
    )
}
export default Stickytext;