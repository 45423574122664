import React , {useState } from 'react';
import  axios  from 'axios';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Avatar,
    TextField,
    Typography
  } from '@mui/material';
import Home from './home';
import { DataGrid } from '@mui/x-data-grid';
import { FilePresent, Image } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Img from './img';
import baseurl from './settings.json';
 
function Loggedin(props) {
  const[uauth, setuauth]= useState([]);
  const navigate= useNavigate();
  useEffect( ()=>{
    const token= localStorage.getItem("token");

    if (!token || token === "undefined"){
      navigate("/login");
    }else{
      axios.get(baseurl.baseurl+"/isUserAuth",  {
      token: localStorage.getItem("token")
      }
      ).then((response) => {
        if (response.data.auth === false) {
         localStorage.removeItem("token");
         navigate('/login');
         } else {
setuauth(response.data.auth);
        }
      })
    }
  },[!uauth])
  return (
    <>
    <Home />
    <div style={{alignItems:'center', paddingLeft:'45%', fontSize:'20px', fontStyle:'Bold', paddingTop:'20px'}}>Welcome...</div>
    </>
  )
}

export default Loggedin;