import React, { useEffect, useState } from 'react';

import Home from './home';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import baseurl from './settings.json';
function Innerpages(props) {
    const navigate= useNavigate();
    const [page, setPage] = React.useState([]);
    const [values, setValues] = useState({
        pageheading: '',
        pagesubheading: '',
        bullets: '',
       dataheading:'',
       datadescription:'',
       databullets:'',
       quotation:''
      });
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });

      };
      const handleChangeSelect = (event) => {
        setPage(event.target.value);    
        getpagedata(event.target.value);
      };
      const handlesave=()=>{
        axios.post(baseurl.baseurl+"/savepagedata",  
        {pagename: page,
        token : localStorage.getItem("token"),
        pageheading : values.pageheading,
        pagesubheading : values.pagesubheading,
        bullets: values.bullets,
        dataheading: values.dataheading,
        datadesciption: values.datadescription,
        databullets: values.databullets,
        quotation: values.quotation
        }
        ).then((response) => {
          if (response.data.auth === false) {
           localStorage.removeItem("token");
           navigate('/login');
          } else {
          alert(response.data.message);
          }
        })
      }
      const getpagedata=(pagename)=>{
        console.log(pagename);
        axios.get(baseurl.baseurl+'/getpagedata?pagename='+pagename+'&token='+localStorage.getItem("token")
            ).then((response)=>{
                if (response.data.auth === false){
                    navigate('/login');
                    return;
                }
            if (response.data.status==="Success"){
                console.log(response.data.results);
            setValues({
                pageheading: response.data.results[0].pageheading,
                pagesubheading: response.data.results[0].pagesubheading,
                bullets: response.data.results[0].bullets,
                dataheading: response.data.results[0].dataheading,
                datadescription: response.data.results[0].datadesciption,
                databullets: response.data.results[0].databullets,
                quotation: response.data.results[0].quotation});
           
            }else
            {
             console.log(response.data.message);
            }
            })
      }
    return (
        <>
            <Home />
            <Grid container columnSpacing={1} rowSpacing={1} my={4} paddingLeft={2} paddingRight={2}>
                <Grid item xs={12} sm={6}>
                    <Box>
                    <form
                            autoComplete="off"
                            noValidate
                            {...props}
                        >
                            <Card>
                                <CardHeader
                                    subheader="Enter Details Below"
                                    title="Product Pages Content Change"
                                />
                                <Divider />
                                <CardContent>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                        <FormControl fullWidth>
                                                <InputLabel id="innerpages-label">Products Page</InputLabel>
                                                <Select labelId="innerpages-label" id="pagename"
                                                    value={page}
                                                    label="Products Page"
                                                    onChange={handleChangeSelect}
                                                    defaultValue=""
                                                >
                                                    <MenuItem value="Insight Analytics">Insight Analytics</MenuItem>
                                                    <MenuItem value="Engagement">Engagement</MenuItem>
                                                    <MenuItem value="Sentiment Analysis">Sentiment Analysis</MenuItem>
                                                    <MenuItem value="Competitor Analysis">Competitor Analysis</MenuItem>
                                                    <MenuItem value="Brand Goal Attainment">Brand Goal Attainement</MenuItem>
                                                    <MenuItem value="Case Management">Case Management</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Page Heading"
                                                name="ph"
                                                onChange={handleChange}
                                                required
                                                value={values.pageheading}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Page Sub Heading"
                                                name="pagesubheading"
                                                onChange={handleChange}
                                                required
                                                value={values.pagesubheading}
                                                variant="outlined"
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bullets"
                                                name="bullets"
                                                onChange={handleChange}
                                                required
                                                value={values.bullets}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                helperText='Enter text with comma seperated'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Heading-2"
                                                name="dataheading"
                                                onChange={handleChange}
                                                required
                                                value={values.dataheading}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Data Description"
                                                name="datadescription"
                                                onChange={handleChange}
                                                required
                                                value={values.datadescription}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Data Bullets"
                                                name="databullets"
                                                onChange={handleChange}
                                                required
                                                value={values.databullets}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                                helperText='Enter text with comma seperated'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Quotation"
                                                name="quotation"
                                                onChange={handleChange}
                                                required
                                                value={values.quotation}
                                                variant="outlined"
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: 2
                                    }}
                                >
                                    <Typography color={'red'}></Typography>
                                    <LoadingButton
                                        color="primary"
                                        variant="contained"
                                        onClick={handlesave}

                                     >
                                        Save details
                                    </LoadingButton>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Grid>
              
            </Grid>
        </>
    )
}
export default Innerpages;