
import { Grid, Paper, Avatar, Typography, TextField, Divider, FormControlLabel,InputAdornment,IconButton, Checkbox, Button, Link, Input, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { width } from '@mui/system';
import React, { useEffect, useState } from 'react';
import logo from './assets/brand-logo.png';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { LocalSeeOutlined, Lock } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import baseurl from './settings.json';
function Login() {
    const navigate = useNavigate()

    const [checked, setChecked] = useState(false);
    const [isloading, setIsloading]= useState(false);
    const [username, setUsername]= useState('');
    const [pswd, setPswd]= useState('');
    const [message, setMessage]= useState('');
    const handleChange1 = () => {
       if (checked === 'true'){
        setChecked(false);
       }else{
        setChecked(true);
       }
    };
    const [values, setValues] = useState({
        username: '',
        password: '',
      showPassword: Boolean
      });
    const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = () => {
       
      };
      useEffect(()=>{
        // axios.get('http://localhost:3001/isUserAuth',{
        //     headers:{
        //         "x-access-token": localStorage.getItem("token"),
        //     },
        // }).then((response)=>{
        //   console.log(response)\
        // })
    const token=localStorage.getItem("token");
    // if(token){
    //     navigate("/");
    // }
    });
      const loginCheck=()=>{
        console.log(baseurl.baseurl);
        setIsloading(true);
               axios.post(baseurl.baseurl+"/login", {
            username: username,
            password: pswd
        }). then((response)=>{
            console.log(response);
            if(response.data.auth === 'false')
            {
                console.log(response.message);
                setMessage(response.data.message);
            }else
            {
                console.log(response.data);
                localStorage.setItem("token", response.data.token);
                setMessage("Login Successful, Redirecting....");
            navigate('/');
            }
        })
        setIsloading(false);
      }
    return (
        <Paper elivation={10} sx={{ padding: '32px', heigh: '70wh', width: '300px', margin: 'auto', marginTop: '30px' }} >

            <Grid container alignContent='center' justifyContent='cneter' textAlign='center' paddingBottom='10px' style={{ textAlign: "center" }}>
                <Grid item align='center'>
                    <Avatar
                        alt="evalMyBrand"
                        src={logo}
                        sx={{ width: 80, height: 64,  padding:'4px' , borderRadius:'0px'}}
                    />
                </Grid>
                <Grid item margin='auto'>
                    <Typography variant='h6'>Login evalmyBRAND</Typography>
                </Grid>

            </Grid>
            <Divider />
            <Grid padding={'10px'} paddingTop={'20px'}>
                <TextField  error={false} id="outlined-basic" label="User Name" variant="outlined" fullWidth width='250px' value={username} onChange={(e)=>setUsername(e.target.value)}
                 InputProps={{
                    endAdornment: <InputAdornment position="start"> <IconButton tabIndex={-1}><Lock/></IconButton></InputAdornment>,
                  }}/>
            </Grid>
            <Grid padding={'10px'}>
                <TextField error={false} id="outlined-basic" label="Password" variant='outlined' type={values.showPassword ? 'password':'text'} fullWidth width='250px'  value={pswd} onChange={(e)=>setPswd(e.target.value)}
               InputProps={{
                endAdornment: <InputAdornment position="start"> <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                tabIndex={-1}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton></InputAdornment>,
              }}
                ></TextField>
                 
            </Grid>
            {/* <Grid align={'left'} paddingLeft='10px'>
                <FormControlLabel
                    label="Remember Me"
                    control={
                        <Checkbox
                            checked={checked[0] && checked[1]}
                            indeterminate={checked[0] !== checked[1]}
                            onChange={handleChange1}
                        />
                    }
                />
            </Grid> */}
            <Grid align={'left'} padding='10px'>
                <LoadingButton loading={isloading} onClick={loginCheck}
                 variant='contained' width='100wh' fullWidth>Log In</LoadingButton>
                <Typography variant='span'>{message}</Typography>
            </Grid>
<Grid padding='10px'><Typography variant='h7'>Go to <Link href='https://www.evalmybrand.com'>Home Page </Link></Typography></Grid>
        </Paper>



    )
}

export default Login;