import React , {useState } from 'react';
import  axios  from 'axios';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Avatar,
    TextField,
    Typography
  } from '@mui/material';
import Home from './home';
import { DataGrid } from '@mui/x-data-grid';
import { FilePresent, Image } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Img from './img';
import baseurl from './settings.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function Blog(props) {
  const [open, setOpen] = React.useState(false);
  const [openmsg, setOpenmsg] = React.useState(false);
  
  const [msg, setmsg]= useState('');
  const handleClickOpen = (id) => {
    setOpen(true);
    setid(id);
  };
  const [tid, setid] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosemsg = () => {
    setOpenmsg(false);
  };
  const navigate= useNavigate();
  
  const [rows, setrows]= useState([]);
  const handlechangetestimonial=(id)=>{
    axios.post(baseurl.baseurl+'/changeblogid',{
  id: id,
  token: localStorage.getItem("token")
  }).then((response)=>{
    console.log(response);
  if (response.data.status==="Success"){
    getbloglist();
    setmsg('View ID changed Successfully');
    setOpenmsg(true);
  }else if(response.data.auth === false)
  {
    setmsg("Invalid Login Session, Re-Login Please...!!!");
    setOpenmsg(true);
    navigate("/login");
  }else
  {
   console.log(response.data.message);
  }
  })
  }
  const handledeleteview=(id)=>{
  setOpen(false);
    axios.post(baseurl.baseurl+'/deleteblogid',{
  id: id,
  token: localStorage.getItem("token")
  }).then((response)=>{
  if (response.data.status==="Success"){
    getbloglist();
    setmsg('Blog Deleted Successfully');
    setOpenmsg(true);
  }else if(response.data.auth === false)
  {
    setmsg("Invalid Login Session, Re-Login Please...!!!");
    setOpenmsg(true);
    navigate("/login");
  }else
  {
   console.log(response.data.message);
  }
  })
  }
  const columns = [
    
    { field: 'id', headerName: 'Db ID', height: 100, width: 150, hideable: true},
    { field: 'heading', headerName: 'Heading', height: 100, width: 150 },
    { field: 'content', headerName: 'Description', height: 100, width: 150 },
    { field: 'link', headerName: 'Link', height: 100, width: 150 },
    {
      field: 'image', headerName: 'Blog Image', flex: 1, renderCell: ({ row: { image } }) => {
        return (
          <a
          href={image}>Photo</a>
                 );
      }
    },
    {
      field: 'eanbledisable', headerName: 'Publish', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e)=>handlechangetestimonial(id)}
          >
  
            <Typography sx={{ ml: "5px" }}  >
              {enabledisable}
            </Typography>
          </Button>
        );
      }
    },
    {
      field: 'delete', headerName: 'Actions', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e)=>handleClickOpen(id)}
          >
  
            <Typography sx={{ ml: "5px" }}  >
              Delete
            </Typography>
          </Button>
        );
      }
    }
  ];
  const  getbloglist=()=>{
    axios.get(baseurl.baseurl+'/getblogslist',{
          headers:{
              "x-access-token": localStorage.getItem("token"),
          },
      }).then((response)=>{
        setrows(response.data);
        console.log(response.data);
      })
  }

  useEffect( ()=>{
    const token= localStorage.getItem("token");
    if (!token){
      navigate("/login");
    }else{
      getbloglist();
    }
  },[!rows])
  
    const [values, setValues] = useState({
        heading: '',
        content: '',
        link: '',
       
      });
    
      const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });

      };

      const [file1, setFile1] = useState("");
      const [fileName1, setFileName1] = useState("");
      
      const savefile1 = (e) => {
        setFile1(e.target.files[0]);
        setFileName1(e.target.files[0].name);
      };
       const uploadFile = async (e) => {
        if (values.heading === '' || values.content === '' || values.link === '' || file1 === '' ) {
          setmsg("All fields are required...");
          setOpenmsg(true);
           return;
        }
        const formData = new FormData();
        formData.append("blogheading", values.heading);
        formData.append("content", values.content);
        formData.append("link", values.link);
        formData.append("blogphoto", file1);
        formData.append("blogphotopath", fileName1);
         formData.append("token", localStorage.getItem("token"));
        console.log(formData);
        try {
          
          const res = await axios.post(
            baseurl.baseurl+"/uploadblogs",
            formData
          );
         console.log(res);
      if (res.data.auth === false) {
        localStorage.removeItem("token");
        setmsg("Login Expired, Please Re-Login...!!!");
        setOpenmsg(true);
       navigate('/login');
        
      } else {
       setmsg("Blog added successfully....!!!");
       setOpenmsg(true);
      getbloglist();
      }
        } catch (ex) {
          console.log(ex);
        }
      };
 
    
  return (
    <>
    <Home/>
    <Grid container columnSpacing={1} rowSpacing={1} my={4} paddingLeft={2} paddingRight={2}>
        <Grid item xs={12} sm={6}>
          <Box>
    <form
    autoComplete="off"
    noValidate
    {...props}
  >
    <Card>
      <CardHeader
        subheader="Enter the Details Below"
        title="Blogs"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              helperText="Please Enter Heading to Display"
              label="Heading"
              name="heading"
              onChange={handleChange}
              required
              value={values.heading}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Description"
              name="content"
              onChange={handleChange}
              required
              value={values.content}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Link"
              name="link"
              onChange={handleChange}
              required
              value={values.link}
              variant="outlined"
              multiline
              rows={1}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            
          >
            <Typography>Add Blog Photo</Typography>
            <TextField type='file' name='photo' variant='outlined' onChange={savefile1} />
          </Grid>
       
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={uploadFile}
        >
          Save details
        </Button>
      </Box>
    </Card>
  </form>
  </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>

            <Card>
              <CardHeader
                subheader="Enable/Disable Blogs List"
                title="List of Blogs"
              />
              <Divider />

              <CardContent >
                {/* Banner Data List */}
                <Box height="50vh">
                  <DataGrid rows={rows} columns={columns} columnVisibilityModel={{id: false}}/>
                </Box>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >
              </Box>
            </Card>

          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you Sure, DO You Want to Delete Selected Blog ...???
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={(e)=>handledeleteview(tid)} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={openmsg}
            onClose={handleClosemsg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Message"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
               {msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosemsg}>Close</Button>
              </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
  </>
  )
}

export default Blog