import React, {useState} from 'react';
import {AppBar, Box, Toolbar, Typography, Avatar, Stack, Button, Paper, Grid, Drawer, IconButton, Divider} from '@mui/material';
import logo from './assets/brand-logo.png';
import {Menu, AddToPhotos, Logout, Reviews, AdUnits, RssFeed, Light} from '@mui/icons-material';
import {useNavigate } from 'react-router-dom';
function Home() {
  const [isDraweropen, setIsdraweropen]= useState(false);
  const navigate= useNavigate();
  const handlelogout=()=>{
    localStorage.removeItem("token");
    navigate('/login');
  }
  const handleinnerpages=()=>{
    navigate("/innerpages")
  }
  const handlebanner=()=>{
    navigate("/banner")
  }
  const handlechangetestimonial=()=>{
    navigate("/testimonials")
  }
  const handleblog=()=>{
    navigate("/blog")
  }
  const handlesticky=()=>{
    navigate("/sticky")
  }
  
  return (
    <Stack>
     
    <AppBar position='static'>
        <Toolbar>
          <IconButton size='medium' edge='start' color='inherit' onClick={()=> setIsdraweropen(true)}><Menu/></IconButton>
      
        <Avatar alt="evalMyBrand" src={logo} sx={{ width: 32, height: 32, padding :'5px' }}/>
        <Typography variant='h5' padding='5px' align='left' component='div' sx={{flexGrow:1}}>evalmyBRAND Panel</Typography>
        <Stack direction='row' space={2} onClick={handlelogout} sx={{cursor:'pointer'}}>
          <Typography variant='body1' component='a' ><IconButton variant='outlined' color='inherit'><Logout/></IconButton>Log Out</Typography>
        </Stack>
        </Toolbar>
        <Drawer anchor='left' open={isDraweropen} onClose={()=> setIsdraweropen(false)}>
          <Box p={2} width="250px" textAlign='center' role='presentation'>
            <Typography variant='h6' component='div' fontStyle='bold'>Admin Panel</Typography>
            <Divider/>
            <Stack direction='column' spacing={2} align='left' padding='5px'>
              <Typography variant='body1' component='a' sx={{cursor:'pointer'}} onClick={handleinnerpages} ><IconButton size='large' color='inherit'><AddToPhotos/></IconButton>Product Pages</Typography>
              <Typography variant='body1' component='a' sx={{cursor:'pointer'}} onClick={handlechangetestimonial}><IconButton size='large' color='inherit'><Reviews/></IconButton>Testimonials</Typography>
              <Typography variant='body1' component='a' sx={{cursor:'pointer'}} onClick={handlebanner}><IconButton size='large' color='inherit'><AdUnits/></IconButton>Banner Lines</Typography>
              <Typography variant='body1' component='a' sx={{cursor:'pointer'}} onClick={handleblog}><IconButton size='large' color='inherit'><RssFeed/></IconButton>External Links Blog</Typography>
              <Typography variant='body1' component='a' sx={{cursor:'pointer'}} onClick={handlesticky}><IconButton size='large' color='inherit'><Light/></IconButton>Notification</Typography>
              
            </Stack>
          </Box>
          </Drawer>
    </AppBar>
    
    </Stack>
  )
}

export default Home