import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Avatar,
  TextField,
  Typography
} from '@mui/material';
import Home from './home';
import { DataGrid } from '@mui/x-data-grid';
import { FilePresent, Image } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Img from './img';
import baseurl from './settings.json';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function Testimonalsentry(props) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [openmsg, setOpenmsg] = React.useState(false);
  
  const [msg, setmsg]= useState('');
  const handleClickOpen = (id) => {
    setOpen(true);
    setid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosemsg = () => {
    setOpenmsg(false);
  };
  const [rows, setrows] = useState([]);
  const [tid, setid] = useState(null);
  const handlechangetestimonial = (id) => {
    axios.post(baseurl.baseurl + '/changetestimonialid', {
      id: id,
      token: localStorage.getItem("token")
    }).then((response) => {
      console.log(response);
      if (response.data.status === "Success") {
        gettestimonialslist();
        setmsg('View ID changed Successfully');
        setOpenmsg(true);
      } else if (response.data.auth === false) {
        setmsg("Invalid Login Session, Re-Login Please...!!!");
        setOpenmsg(true);
        navigate("/login");
      } else {
        console.log(response.data.message);
      }
    })
  }
  const handledeleteview = (id) => {
    setOpen(false);
if (id === null) return;
    axios.post(baseurl.baseurl + '/deletetestmonialid', {
      id: id,
      token: localStorage.getItem("token")
    }).then((response) => {
      if (response.data.status === "Success") {
        gettestimonialslist();
        setmsg('Testimonial Deleted Successfully');
        setOpenmsg(true);
      } else if (response.data.auth === false) {
        setmsg("Invalid Login Session, Re-Login Please...!!!");
        setOpenmsg(true)
        navigate("/login");
      } else {
        console.log(response.data.message);
      }
    })
  }
  const columns = [

    { field: 'id', headerName: 'Db ID', height: 100, width: 150, hideable: true },
    { field: 'name', headerName: 'Name', height: 100, width: 150 },
    { field: 'designation', headerName: 'Designation', height: 100, width: 150 },
    { field: 'opinion', headerName: 'Opinion', height: 100, width: 150 },
    {
      field: 'photopath', headerName: 'Photo', flex: 1, renderCell: ({ row: { photopath } }) => {
        return (
          <a
            href={photopath}>Photo</a>
        );
      }
    },
    {
      field: 'companylogopath', headerName: 'Logo', flex: 1, renderCell: ({ row: { companylogopath } }) => {
        return (
          <a
            href={companylogopath}>Company Logo</a>
        );
      }
    },
    {
      field: 'eanbledisable', headerName: 'Publish', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e) => handlechangetestimonial(id)}
          >

            <Typography sx={{ ml: "5px" }}  >
              {enabledisable}
            </Typography>
          </Button>
        );
      }
    },
    {
      field: 'delete', headerName: 'Actions', flex: 1, renderCell: ({ row: { enabledisable, id } }) => {
        return (
          <Button
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            variant='contained'
            borderRadius="4px"
            onClick={(e) => handleClickOpen(id)}
          >

            <Typography sx={{ ml: "5px" }}  >
              Delete
            </Typography>
          </Button>
        );
      }
    }
  ];
  const gettestimonialslist = () => {
    axios.get(baseurl.baseurl + '/gettestimonalslist', {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setrows(response.data);
      console.log(response.data);
    })
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      gettestimonialslist();
    }
  }, [!rows])

  const [values, setValues] = useState({
    firstName: '',
    designation: '',
    opinion: '',

  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

  };

  const [file1, setFile1] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [file2, setFile2] = useState("");
  const [fileName2, setFileName2] = useState("");

  const savefile1 = (e) => {
    setFile1(e.target.files[0]);
    setFileName1(e.target.files[0].name);
  };
  const savefile2 = (e) => {
    setFile2(e.target.files[0]);
    setFileName2(e.target.files[0].name);
  };
  const uploadFile = async (e) => {
    if (values.firstName === '' || values.designation === '' || values.opinion === '' || file1 === '' || file2 === '') {
      setmsg("All fields are required...");
      setOpenmsg(true);
      return;
    }
    const formData = new FormData();
    formData.append("tname", values.firstName);
    formData.append("desgn", values.designation);
    formData.append("opinion", values.opinion);
    formData.append("photo", file1);
    formData.append("photopath", fileName1);
    formData.append("company", file2);
    formData.append("companypath", fileName2);
    formData.append("token", localStorage.getItem("token"));
    console.log(formData);
    try {

      const res = await axios.post(
        baseurl.baseurl + "/uploadtestimonals",
        formData
      );
      console.log(res);
      if (res.data.auth === false) {
        localStorage.removeItem("token");
        setmsg("Login Expired, Please Re-Login...!!!");
        setOpenmsg(true);
        navigate('/login');

      } else {
        setmsg("Testimonial added successfully....!!!")
        setOpenmsg(true);
        gettestimonialslist();
      }
    } catch (ex) {
      console.log(ex);
    }
  };


  return (
    <>
      <Home />
      <Grid container columnSpacing={1} rowSpacing={1} my={4} paddingLeft={2} paddingRight={2}>
        <Grid item xs={12} sm={6}>
          <Box>
            <form
              autoComplete="off"
              noValidate
              {...props}
            >
              <Card>
                <CardHeader
                  subheader="Enter the Details Below"
                  title="Testimonials"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        helperText="Please Enter Name to Display"
                        label="Name"
                        name="firstName"
                        onChange={handleChange}
                        required
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Designation"
                        name="designation"
                        onChange={handleChange}
                        required
                        value={values.designation}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Enter Testimonial Details"
                        name="opinion"
                        onChange={handleChange}
                        required
                        value={values.opinion}
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}

                    >
                      <Typography>Add Photo</Typography>
                      <TextField type='file' name='photo' variant='outlined' onChange={savefile1} />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}

                    >
                      <Typography>Add Company Logo</Typography>
                      <TextField type='file' name='company' variant='outlined' onChange={savefile2} />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={uploadFile}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>

            <Card>
              <CardHeader
                subheader="Enable/Disable Testimonials"
                title="List of Testimonials"
              />
              <Divider />

              <CardContent >
                {/* Banner Data List */}
                <Box height="50vh">
                  <DataGrid rows={rows} columns={columns} columnVisibilityModel={{ id: false }} />
                </Box>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >
              </Box>
            </Card>

          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you Sure, DO You Want to Delete Selected Testimonial...???
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={(e)=>handledeleteview(tid)} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dialog
            open={openmsg}
            onClose={handleClosemsg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Message"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
               {msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosemsg}>Close</Button>
              </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  )
}

export default Testimonalsentry