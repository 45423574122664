import { Avatar } from '@mui/material'
import React from 'react'

function Img(props) {
  return (
    <Avatar
          alt="evalMyBrand"
          src={props.location}
          sx={{ width: 100, height: 100, border: '1px solid lightgray' }}
      />
  )
}

export default Img