
import Login from './components/login';
import Home from './components/home';
import './App.css';
import Banner from './components/banner';
import Img from './components/img';
import Testimonals  from './components/testimonalsentry';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Loggedin from './components/loggedin';
import { Avatar } from '@mui/material';
import Innerpages from './components/innerpages';
import Blogs from './components/blog';
import Stickytext from './components/stickytext';
import React from "react"; 

function App() {
 
  return (
    <Router>
      <Routes>
     <Route path="/login" element={<Login />} />
        <Route path="/" element={<Loggedin />} />
        <Route path="/testimonials" element={<Testimonals />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/innerpages" element={<Innerpages />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/sticky" element={<Stickytext />} />
       
        <Route path="/public/images/:image" element={<Img/>}/>
      </Routes>
    </Router>
  );
}

export default App;
